<template>
  <v-container fluid class="custom-container-details">
    <div>
      <v-row v-if="successExe" dense no-gutters class="px-0">
        <v-col v-if="successExe" cols="12">
          <v-alert color="success" :data-cy="`${successExe} ${successEventId}`">
            {{ successExe }} <span class="clickable-simple underline-simple" @click="goToEventLogs()">{{ successEventId.eventId }}</span>
          </v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters>
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
          md="9"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
        >
          <v-tabs
            v-model="tab"
            class="custom-tab-design"
            background-color="transparent"
          >

            <v-tab
              :key="0"
              data-cy="tab-details"
              class="ml-2"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
              class="ml-2"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class=" custom-bg pt-6">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-1 fill-height custom-bg">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="plugin.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          class="required-asterisk copy-name-icon mb-3"
                          :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                          data-cy="plugin-name"
                          append-icon="mdi-vector-combine"
                          @click:append="copyName()"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-sm-1 text-right pb-2">
                      <v-btn
                        outlined

                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        <v-icon
                          left
                          dark
                        >
                          {{ plugin.status === 'ACTIVE' ? 'mdi-toggle-switch-off ' : 'mdi-toggle-switch' }}
                        </v-icon>
                        {{ plugin.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters :class="isEdit ? 'pb-0' : 'pb-2'">
                    <v-col
                      cols="12"
                      sm="9"
                    >
                      <v-text-field
                        v-model="plugin.className"
                        outlined
                        dense
                        :label="$lang.labels.className"
                        required
                        :rules="[v => !!v || $lang.labels.required]"
                        :readonly="!userCanEdit"
                        data-cy="plugin-className"
                        class="pr-sm-1 required-asterisk"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="isEdit" wrap no-gutters class="pb-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pb-2 pr-sm-1"
                    >
                      <div class="d-inline-flex align-center max-width">
                        <v-text-field
                          ref="fileTextField"
                          v-model="file"
                          prepend-icon="mdi-paperclip"
                          outlined
                          dense
                          readonly
                          hide-details
                          :label="$lang.actions.clickToImport"
                          class="min-w"
                          @click="startInput('fileInput')"
                          @click:prepend="startInput('fileInput')"
                        />
                        <input
                          ref="fileInput"
                          style="display: none;"
                          type="file"
                          accept=".jar,application/java-archive"
                          @change="onFileChange($event)"
                        >
                        <v-btn
                          outlined
                          :disabled="!file || !fileData || !userCanEdit"
                          color="success"
                          class="button-default-width ml-1"
                          @click="importPluginFunction()"
                        >
                          {{ $lang.actions.import }}
                        </v-btn>
                        <v-btn
                          :disabled="!plugin.id || !plugin.filename || lock || !userCanEdit"
                          color="primary"
                          outlined
                          :loading="loading"
                          class="button-default-width ml-2 color-black"
                          @click="downloadPluginFunction()"
                        >
                          {{ $lang.actions.download }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="isEdit" wrap no-gutters class="pb-0">
                    <v-col
                      cols="12"
                      sm="5"
                    >
                      <v-text-field
                        v-model="plugin.filename"
                        outlined
                        dense
                        :label="$lang.labels.filename"
                        readonly
                        data-cy="plugin-filename"
                        class="pr-sm-1"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        v-model="plugin.size"
                        outlined
                        dense
                        readonly
                        :label="$lang.labels.sizeInBytes"
                        data-cy="plugin-size"
                        class="pr-sm-1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters class="pb-4">
                    <v-col
                      cols="12"
                      class="pb-2"
                    >
                      <div class="max-width d-flex align-center">
                        <h3>{{ $lang.header.actions }}</h3>
                        <v-btn
                          color="accent"
                          class="ml-2"
                          icon
                          :disabled="!userCanEdit"
                          data-cy="plugin-action-add"
                          @click="plugin.actions.push('')"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-divider></v-divider>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <template v-for="(action, i) in plugin.actions">
                        <div :key="i" class="max-width d-inline-flex align-center pt-2">
                          <v-text-field
                            v-model="plugin.actions[i]"
                            outlined
                            dense
                            :label="`${$lang.labels.action} - ${i+1}`"
                            required
                            hide-details
                            class="required-asterisk"
                            :rules="[v => !!v || $lang.labels.required]"
                            :readonly="!userCanEdit"
                            :data-cy="`plugin-action-${i+1}`"
                          ></v-text-field>
                          <v-btn
                            class="ml-2"
                            icon
                            color="error"
                            :disabled="!userCanEdit"
                            :data-cy="`plugin-action-delete-${i+1}`"
                            @click="plugin.actions.splice(i, 1)"
                          >
                            <v-icon
                              size="25"
                            >mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-divider class="mb-4" />
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          outlined
                          color="primary"
                          :to="{ name: 'plugins', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          outlined
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="plugin.name"
                          :title="$lang.actions.delete"
                          type="plugin"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'plugin-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deletePluginFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'plugins' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-inline-flex">
                        <!--                      <v-btn-->
                        <!--                        :disabled="!isEdit || lock || !userCanEdit"-->
                        <!--                        color="accent"-->
                        <!--                        class="button-default-width mr-2"-->
                        <!--                        style="color: black"-->
                        <!--                        @click="execute()"-->
                        <!--                      >-->
                        <!--                        {{ $lang.actions.execute }}-->
                        <!--                      </v-btn>-->
                        <v-btn
                          outlined
                          :disabled="!isFormValid"
                          color="primary"
                          class="mr-2 color-accent-text"
                          @click="copyCreateResource(plugin, 'pluginCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-content-copy
                          </v-icon>
                          {{ $lang.actions.copyCreate }}
                        </v-btn>
                        <v-btn
                          :disabled="!isFormValid"
                          color="primary"

                          data-cy="plugin-submit"
                          @click="submit()"
                        >
                          <v-icon
                            left
                            dark
                            class="mr-1"
                          >
                            mdi mdi-floppy
                          </v-icon>
                          {{ $lang.actions.submit }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restorePlugin($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <multi-errors-snackbar
      :show="showSnackErrors"
      :errors="errorsForSnackbar"
      @close="showSnackErrors = false; errorsForSnackbar = []"
    />
  </v-container>
</template>

<script>
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import {
  disablePluginUsingGET as disablePlugin,
  enablePluginUsingGET as enablePlugin,
  getPluginByIdUsingGET as getPlugin,
  updatePluginUsingPUT as updatePlugin,
  createPluginUsingPOST as createPlugin,
  executeUsingGET as executePlugin,
  getHistoriesUsingGET as getHistories,
  deletePluginUsingDELETE as deletePlugin,
  downloadPluginJarUsingGET as downloadPlugin
} from '@/utils/api'
import auth from '@/auth'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import copy from 'copy-to-clipboard'
import { mapActions } from 'vuex'
import { copyCreateResource, getRolesWithoutAuth } from '@/utils/helpers'
import { bus } from '@/main'

export default {
  components: {
    ActionButtonWithConfirmation,
    MultiErrorsSnackbar,
    Table,
    UserRolesSelect
  },
  data() {
    return {
      tab: 0,
      err: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      success: '',
      successExe: '',
      successEventId: '',
      valid: false,
      isEdit: false,
      loading: true,
      isLoadingProcesses: false,
      lock: false,
      plugin: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        status: '',
        className: '',
        roles: [],
        actions: [''],
        fileName: '',
        size: ''
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      file: null,
      fileData: null,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.plugin.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.plugin.modifiedOn)}, ID: ${this.plugin.id}`
    },
    isFormValid() {
      return this.valid && this.userCanEdit && !this.lock && this.plugin.actions.length > 0
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val)
    },
    tab(val) {
      if (val === 2) {
        this.fetchHistory()
      }
    }
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  created() {
    this.isEdit = this.$route.name === 'pluginEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'PLUGIN_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getPlugin({ id: this.$route.params.id })
            .then((res) => {
              this.plugin = res.data.data

              this.editRolesIds = this.plugin.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.plugin.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.plugin.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              this.loading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else if (this.$route.params.restore) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.plugin = this.$route.params.restore
          this.plugin.id = 0
          this.loading = false
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.plugin = this.$route.params.copy
          }
          this.loading = false
        }
      })
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),
    copyCreateResource,
    downloadPluginFunction() {
      this.loading = true
      this.lock = true
      downloadPlugin({ id: this.plugin.id })
        .then((res) => {
          if (res.status !== 200) {
            this.addSnackbar({
              message: res?.response?.data?.statusText ? res.response.data.statusText : this.$lang.errors.somethingWentWrong,
              timeout: 5000,
              color: 'error'
            })

            this.loading = false
            this.lock = false

            return
          }
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', this.plugin.filename)
          document.body.appendChild(link)
          link.click()

          this.addSnackbar({
            message:  this.$lang.success.downloadSuccess,
            timeout: 5000,
            color: 'success'
          })

          this.loading = false
          this.lock = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    copyName() {
      if (this.plugin.name) {
        copy(this.plugin.name)
        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {
        this.addSnackbar({
          message: this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    deletePluginFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deletePlugin({ id: this.plugin.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    startInput(name) {
      this.$refs[name].click()
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      this.fileData = files[0]
      this.file = `${this.$lang.labels.fileLoaded}: ${this.fileData.name}`
    },
    async importPluginFunction() {
      this.err = ''

      if (!this.fileData) return

      this.loading = true
      this.lock = true

      const data = new FormData()

      data.append('file', this.fileData)

      fetch(`/api/plugin/${this.plugin.id}/jar`, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'x-auth-token': auth.getToken()
        },
        body: data
      }).then((response) => {
        return response.json()
      }).then((res) => {
        if (res && res.status !== 'SUCCESS') {
          const errorMessage = `${this.$lang.errors.pluginImport}: ${res.statusText}`

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }

        this.plugin.filename = res.data.filename
        this.plugin.size = res.data.size

        this.file = ''
        this.fileData = ''

        this.loading = false
        this.lock = false

        this.addSnackbar({
          message: this.$lang.success.pluginImport,
          timeout: 5000,
          color: 'success'
        })
      }).catch((err) => {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      })
    },
    fetchHistory(options) {
      if (!this.plugin.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.plugin.id
      obj.resourceType = 'PLUGIN'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    restorePlugin(data) {
      this.plugin = data

      if (this.plugin && this.plugin.roles) {
        this.editRolesIds = this.plugin.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.plugin.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.plugin.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    goToEventLogs() {
      const routeData = this.$router.resolve({
        name: 'logs-events',
        query: {
          eventId: this.successEventId.eventId,
          processId: this.successEventId.processId,
          dateFrom: this.successEventId.dateFrom,
          dateTill: this.successEventId.dateTill
        }
      })

      window.open(routeData.href, '_blank')
    },
    async execute() {
      this.lock = true

      this.successEventId = {
        eventId: '',
        processId: '',
        dateFrom: '',
        dateTill: ''
      }

      let res = null

      const { id, processId } = this.plugin

      try {

        res = await executePlugin({ id })

        if (res && res.status !== 200) {

          this.addSnackbar({
            message:  this.$lang.errors.pluginExecute,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        this.successExe = this.$lang.success.pluginExecute
        this.successEventId = {
          eventId: res.data.data.eventId,
          processId: processId,
          dateFrom: this.$options.filters.formatDate(new Date),
          dateTill: this.$options.filters.formatDate(new Date)
        }
        setTimeout(() => this.successExe = '', 10000)
        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {

        this.addSnackbar({
          message:  this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.lock = true

      let res = null

      const { id } = this.plugin

      if (!this.isEdit) {
        delete this.plugin.id
        this.plugin.status = 'ACTIVE'
      }
      delete this.plugin.createdOn
      delete this.plugin.modifiedOn
      delete this.plugin.size

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.plugin.roles = tempRoles

      this.plugin.actions = this.plugin.actions.filter((x) => x !== '')

      try {

        res = this.isEdit ? await updatePlugin({ id, body: this.plugin }) : await createPlugin({ body: this.plugin })

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {
            const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.pluginUpdate : this.$lang.errors.pluginCreate)

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.pluginUpdated : this.$lang.success.pluginCreated
        this.addSnackbar({
          message: this.success,
          timeout: 5000,
          color: 'success'
        })
        if (!this.isEdit) {
          this.isEdit = true
          this.$router.push({
            name: 'pluginEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.plugin.status === 'ACTIVE' ? await disablePlugin({ id: this.plugin.id }) : await enablePlugin({ id: this.plugin.id })

        if (res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.pluginStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false
        }
        const successMessage = this.plugin.status !== 'ACTIVE' ? this.$lang.success.pluginActivated : this.$lang.success.pluginDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.plugin.status = this.plugin.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
</style>
